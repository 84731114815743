<template>
    <div>
        <zw-table ref="table"
                  :tableName="tableName"
                  base-table="offering"
                  :column-params="{type}"
                  columnsPrefix="common.column.sale."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :selectedSource="selectedSource"
                  :tbody-tr-class="rowClass"
                  :actions-list="getActions()"
                  :title="'common.title.' + (type || 'sales')"
                  :sticky="false"
                  sticky-header="100%"
        >
            <template #cell(selected)="data">
                <div>
                    <b-form-checkbox :key="data.item.id"
                                     v-if="data.item.selectable && !['all','filtered'].includes(selectedSource)"
                                     @change="selectRow($event, data.item)"
                                     :checked="selected.includes(data.item)"
                    ></b-form-checkbox>
                </div>
            </template>

            <template #cell(projectProduction.id)="data">
                <a @click.prevent="$root.$children[0].openModal('kva-modal', data.item.id)"
                   href="#">{{ zeroPad(data.item['projectProduction.id'], 4) }}</a>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import Lf_document from "@/columns/offering/lf_document";

export default {
    name: 'offerings',
    components: {Lf_document},
    mixins: [commonTable],
    props: {
        customerId: {
            type: Number,
            required: true
        },
        customerActive: null,
    },
    data() {
        return {
            loading: true,
            type: 'kva',
            project_form_type: null,
            lfFile: {},
            invoiceFile: {},
            saveFilters: false,
            tableName: 'customer-sales',
        }
    },
    mounted() {
        this.type = this.$route.meta.type || 'kva'
        this.project_form_type = this.type == 'supply' ? 'provider' : null
    },
    methods: {
        ...mapGetters('Sales', ['getSalesTable']),
        getActions() {
            return [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    disabled: this.customerActive === 0,
                    click: () => {
                        this.$root.$children[0].openModal('project-form', {
                            type: this.project_form_type,
                            customerId: this.customerId
                        }, null, {width: '800px'})
                    }
                },
                {
                    title: this.$t('common.button.scan_and_print'),
                    icon: 'barcode',
                    click: () => {
                        this.$root.$children[0].openModal('scan-and-print-modal', {}, null, {width: '800px'})
                    },
                },
                {
                    title: this.$t('common.button.scan'),
                    icon: 'barcode',
                    click: () => {
                        this.$root.$children[0].openModal('scan-modal', {}, null, {width: '800px'})
                    },
                },
                {
                    title: this.$t('common.button.mass_update'),
                    icon: 'list',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    hide: !this.$root.hasAccess('sales', 'update'),
                    click: () => {
                        this.openChangeAttribute()
                    },
                },
                {
                    title: this.$t('common.button.deliveryLabelPrint'),
                    icon: 'gift',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.createMergedFiles('lieferschein')
                    },
                },
                {
                    title: this.$t('common.button.download_lf_file') + '<br/>(' + (this.lfFile ? this.lfFile.date : '') + ') ' + (this.lfFile ? this.lfFile.user : ''),
                    icon: 'paperclip',
                    hide: !this.lfFile,
                    click: () => {
                        this.openLfFile()
                    },
                },
                {
                    title: this.$t('common.button.totalInvoicePrint'),
                    icon: 'euro-sign',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.createMergedFiles('rechnung')
                    },
                },
                {
                    title: this.$t('common.button.download_invoice_file') + '<br/>(' + (this.invoiceFile ? this.invoiceFile.date : '') + ') ' + (this.invoiceFile ? this.invoiceFile.user : ''),
                    icon: 'paperclip',
                    hide: !this.invoiceFile,
                    click: () => {
                        this.openInvoiceFile()
                    },
                },
                {
                    title: this.$t('common.button.invoiceZip'),
                    icon: 'download',
                    disabled: !this.selected.length,
                    click: () => {
                        this.downloadInvoicesZip()
                    },
                },
                {
                    title: this.$t('common.button.upload_amazon_report'),
                    icon: 'list',
                    click: () => {
                        this.$root.$children[0].openModal('upload-file-modal', {store_dispatch_name: 'Sales/saveArticlesMap'}, this.refreshTable, {width: '800px'});
                    },
                },
                {
                    title: this.$t('common.button.delete'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'trash',
                    click: () => {
                        this.deleteSales()
                    },
                }
            ]
        },
        ctxBaseParams() {
            return {
                'tableName': this.tableName,
            }
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx,
                'Sales/fetchCustomerSales',
                'getSalesTable',
                {'lfFile': 'lfFile', 'invoiceFile': 'invoiceFile'});
        },
        ctxAdditionalParams() {
            return {
                'customer_id': this.customerId
            }
        },
        downloadInvoicesZip() {
            let ids = this.selected.map(row => row.id);

            window.open('/sales/index/invoiceszip/ids/' + ids.join('-'))
        },
        downloadLfFile() {
            this.$root.downloadDocument('merged_lieferschein')
        },
        openLfFile() {
            this.$root.openDocument('merged_lieferschein')
        },
        downloadInvoiceFile() {
            this.$root.downloadDocument('merged_rechnung')
        },
        openInvoiceFile() {
            this.$root.openDocument('merged_rechnung')
        },
        deleteSales() {
            let sendData = this.getSelectedData('sales')

            this.$removeConfirmAuth('Sales/deleteSales', sendData, this.commonJobsSave)
        },
        createMergedFiles(type) {
            if (this.selectedSource == 'all') {
                this.$confirm(
                    {
                        message: this.$t('common.confirmations.generate_all'),
                        button: {
                            no: this.$t('common.button.no'),
                            yes: this.$t('common.button.yes')
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.sendCreateMergedFiles(type)
                            }
                        }
                    }
                )
            } else {
                this.sendCreateMergedFiles(type)
            }
        },
        sendCreateMergedFiles(type) {
            this.loading = true
            let sendData = this.getSelectedData('sales')

            this.$store.dispatch('Invoice/generateMergedFiles', {
                ...{
                    docType: type,
                },
                ...sendData
            })
                .then((response) => {
                    if (response.data.batch_group) {
                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                    }
                    this.$root.showMessage(
                        this.$t('common.form.title.success'),
                        this.$t('multichannel.text.queued_count', {count: response.data.count}),
                        'success'
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
        openChangeAttribute() {
            let sendData = this.getSelectedData('sales')

            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'offering',
                selectedData: sendData,
            }, this.refreshTable, {width: '800px'})
        }
        ,
        rowClass(item) {
            if (item && item.invoices.length > 0) {
                var row_class = '';
                item.invoices.forEach(sale => {
                    if (sale.iscanceled) {
                        row_class += ' canceled';
                    }
                })

                return row_class;
            }
        }
        ,
        zeroPad(num, places) {
            let zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
        }
        /*...mapGetters('Sales', ['getProcesses']),
        shown() {
            this.$store.dispatch('Sales/fetchProcesses', this.customerId)
                .then(() => {
                    this.loading = false
                }, () => {
                    this.loading = false
                })
        },
         */
    }
}
</script>